import axios from 'axios'
import {
  PRE_REGISTER_REGISTER_REQUEST,
  PRE_REGISTER_REGISTER_SUCCESS,
  PRE_REGISTER_REGISTER_FAIL,
  PRE_REGISTER_USER_REQUEST,
  PRE_REGISTER_USER_SUCCESS,
  PRE_REGISTER_USER_FAIL,
  PRE_REGISTER_BY_EMAIL_REQUEST,
  PRE_REGISTER_BY_EMAIL_SUCCESS,
  PRE_REGISTER_BY_EMAIL_FAIL,
  PRE_REGISTER_LIST_REQUEST,
  PRE_REGISTER_LIST_SUCCESS,
  PRE_REGISTER_LIST_FAIL,
  PRE_REGISTER_UPDATE_REQUEST,
  PRE_REGISTER_UPDATE_SUCCESS,
  PRE_REGISTER_UPDATE_FAIL,
  PRE_REGISTER_DELETE_REQUEST,
  PRE_REGISTER_DELETE_SUCCESS,
  PRE_REGISTER_DELETE_FAIL,
} from '../constants/preRegisterConstants'

export const registerPreRegister = (preRegisterdata) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRE_REGISTER_REGISTER_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post('/api/pre-register', preRegisterdata, config)

    dispatch({ type: PRE_REGISTER_REGISTER_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRE_REGISTER_REGISTER_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
export const registerPreRegisterUser = (preRegisterdata) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRE_REGISTER_USER_REQUEST })

    const { data } = await axios.post(`/api/pre-register/${preRegisterdata.code.replaceAll('/', '*')}`, preRegisterdata)

    dispatch({ type: PRE_REGISTER_USER_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRE_REGISTER_USER_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
export const getPreRegisterByEmail = (token) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRE_REGISTER_BY_EMAIL_REQUEST })

    const { data } = await axios.get(`/api/pre-register/${token}`)

    dispatch({ type: PRE_REGISTER_BY_EMAIL_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRE_REGISTER_BY_EMAIL_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
export const getPreRegisterList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PRE_REGISTER_LIST_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/pre-register/list`, config)

    dispatch({ type: PRE_REGISTER_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRE_REGISTER_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
export const updatePreRegister = (preRegisterData) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRE_REGISTER_UPDATE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/pre-register/${preRegisterData._id}`, preRegisterData, config)

    dispatch({ type: PRE_REGISTER_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRE_REGISTER_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
export const deletePreRegister = (preRegisterId) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRE_REGISTER_DELETE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.delete(`/api/pre-register/${preRegisterId}`, config)

    dispatch({ type: PRE_REGISTER_DELETE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRE_REGISTER_DELETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
