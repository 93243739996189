import axios from 'axios'
import {
	INVESTOR_PROFILE_REGISTER_REQUEST,
	INVESTOR_PROFILE_REGISTER_SUCCESS,
	INVESTOR_PROFILE_REGISTER_FAIL,
	INVESTOR_PROFILE_BY_ADMIN_REGISTER_REQUEST,
	INVESTOR_PROFILE_BY_ADMIN_REGISTER_SUCCESS,
	INVESTOR_PROFILE_BY_ADMIN_REGISTER_FAIL,
	INVESTORS_PROFILE_BY_USER_REQUEST,
	INVESTORS_PROFILE_BY_USER_SUCCESS,
	INVESTORS_PROFILE_BY_USER_FAIL,
	INVESTOR_PROFILE_UPDATE_REQUEST,
	INVESTOR_PROFILE_UPDATE_SUCCESS,
	INVESTOR_PROFILE_UPDATE_FAIL,
	INVESTOR_PROFILE_WITH_USER_REGISTER_REQUEST,
	INVESTOR_PROFILE_WITH_USER_REGISTER_SUCCESS,
	INVESTOR_PROFILE_WITH_USER_REGISTER_FAIL,
	INVESTOR_PROFILE_DELETE_REQUEST,
	INVESTOR_PROFILE_DELETE_SUCCESS,
	INVESTOR_PROFILE_DELETE_FAIL,
	INVESTORS_PROFILE_LIST_REQUEST,
	INVESTORS_PROFILE_LIST_SUCCESS,
	INVESTORS_PROFILE_LIST_FAIL,
	INVESTOR_PROFILE_BY_ID_REQUEST,
	INVESTOR_PROFILE_BY_ID_SUCCESS,
	INVESTOR_PROFILE_BY_ID_FAIL,
	INVESTOR_PROFILE_UPLOAD_DOCUMENTS_REQUEST,
	INVESTOR_PROFILE_UPLOAD_DOCUMENTS_SUCCESS,
	INVESTOR_PROFILE_UPLOAD_DOCUMENTS_FAIL,
	INVESTOR_PROFILE_DELETE_DOCUMENT_REQUEST,
	INVESTOR_PROFILE_DELETE_DOCUMENT_SUCCESS,
	INVESTOR_PROFILE_DELETE_DOCUMENT_FAIL,
	INVESTORS_PROFILE_LIST_FOR_PDL_REQUEST,
	INVESTORS_PROFILE_LIST_FOR_PDL_SUCCESS,
	INVESTORS_PROFILE_LIST_FOR_PDL_FAIL,
	INVESTORS_PROFILE_PAGINATION_LIST_REQUEST,
	INVESTORS_PROFILE_PAGINATION_LIST_SUCCESS,
	INVESTORS_PROFILE_PAGINATION_LIST_FAIL,
} from '../constants/investorProfileConstants'
import { generateFilterParamsString } from 'shared/helpers/generate-filters-params-string'
import { ALL_PAGES_CHANGE } from 'local_redux/constants/tableConstants'
import { PAGE_CHANGE } from 'local_redux/constants/tableConstants'
import { PROJECT_LIST_SUCCESS } from 'local_redux/constants/projectConstant'
import { LOADING_TABLE_PARAMS } from 'local_redux/constants/tableConstants'
import { INVESTOR_PRESCRIBERS_PROFILES_SUCCESS } from 'local_redux/constants/investorConstant'
import { INVESTOR_PRESCRIBERS_PROFILES_REQUEST } from 'local_redux/constants/investorConstant'
import { INVESTOR_PRESCRIBERS_PROFILES_FAIL } from 'local_redux/constants/investorConstant'

export const registerProfileInvestor = (profileInvestorData) => async (dispatch, getState) => {
	try {
		dispatch({ type: INVESTOR_PROFILE_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/profiles', profileInvestorData, config)

		dispatch({ type: INVESTOR_PROFILE_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: INVESTOR_PROFILE_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const registerProfileInvestorByAdmin = (profileInvestorData) => async (dispatch, getState) => {
	try {
		dispatch({ type: INVESTOR_PROFILE_BY_ADMIN_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}
		const fieldsFiles = ['residenceCertificate']

		let formData = new FormData()

		for (let key in profileInvestorData) {
			if (!fieldsFiles.includes(key)) {
				formData.append(key, profileInvestorData[key])
			}
		}
		if (typeof profileInvestorData.residenceCertificate === 'object') {
			formData.append('residenceCertificate', profileInvestorData.residenceCertificate)
		}

		const { data } = await axios.post('/api/profiles/register', formData, config)

		dispatch({ type: INVESTOR_PROFILE_BY_ADMIN_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: INVESTOR_PROFILE_BY_ADMIN_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const investorsProfileByUser = (userId) => async (dispatch, getState) => {
	try {
		dispatch({ type: INVESTORS_PROFILE_BY_USER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		const { data } = await axios.get(
			`/api/profiles/${userId}`,

			config,
		)

		dispatch({ type: INVESTORS_PROFILE_BY_USER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: INVESTORS_PROFILE_BY_USER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getInvestorsProfileList = () => async (dispatch, getState) => {
	try {
		dispatch({ type: INVESTORS_PROFILE_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		const { data } = await axios.get('/api/profiles', config)

		const sortedData = [...data].sort((a, b) => {
			return a.businessName?.localeCompare(b.businessName)
		})

		dispatch({ type: INVESTORS_PROFILE_LIST_SUCCESS, payload: sortedData })
	} catch (error) {
		dispatch({
			type: INVESTORS_PROFILE_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getInvestorsProfilePaginationList = () => async (dispatch, getState) => {
	try {
		dispatch({ type: INVESTORS_PROFILE_PAGINATION_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		const { data } = await axios.get(`/api/profiles/investor-profiles`, config)

		dispatch({ type: INVESTORS_PROFILE_PAGINATION_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: INVESTORS_PROFILE_PAGINATION_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getProfilesForPDL = () => async (dispatch, getState) => {
	try {
		dispatch({ type: INVESTORS_PROFILE_LIST_FOR_PDL_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		const { data } = await axios.get('/api/profiles/pdl', config)

		dispatch({ type: INVESTORS_PROFILE_LIST_FOR_PDL_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: INVESTORS_PROFILE_LIST_FOR_PDL_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const updateInvestorProfile = (profileInfo) => async (dispatch, getState) => {
	try {
		dispatch({ type: INVESTOR_PROFILE_UPDATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}
		const fieldsFiles = ['residenceCertificate']

		let formData = new FormData()

		for (let key in profileInfo) {
			if (!fieldsFiles.includes(key)) {
				formData.append(key, profileInfo[key])
			}
		}
		if (typeof profileInfo?.residenceCertificate === 'object') {
			formData.append('residenceCertificate', profileInfo?.residenceCertificate)
		}

		if (profileInfo?.filesToDelete && Object.keys(profileInfo?.filesToDelete).length > 0) {
			Object.keys(profileInfo?.filesToDelete).forEach((key) => {
				if (profileInfo?.filesToDelete[key]) formData.append(`delete-${key}`, true)
			})
		}

		const { data } = await axios.put(`/api/profiles/${profileInfo._id}`, formData, config)

		dispatch({ type: INVESTOR_PROFILE_UPDATE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: INVESTOR_PROFILE_UPDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const registerProfileInvestorWithUser = (profileInvestorData) => async (dispatch, getState) => {
	try {
		dispatch({ type: INVESTOR_PROFILE_WITH_USER_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}
		const fieldsFiles = ['residenceCertificate', 'profileDniFront', 'profileDniBack', 'kycContract']

		let formData = new FormData()

		for (let key in profileInvestorData) {
			if (!fieldsFiles.includes(key)) {
				formData.append(key, profileInvestorData[key])
			}
		}
		if (typeof profileInvestorData.residenceCertificate === 'object') {
			formData.append('residenceCertificate', profileInvestorData.residenceCertificate)
		}
		if (typeof profileInvestorData.profileDniFront === 'object') {
			formData.append('profileDniFront', profileInvestorData.profileDniFront)
		}
		if (typeof profileInvestorData.profileDniBack === 'object') {
			formData.append('profileDniBack', profileInvestorData.profileDniBack)
		}
		if (typeof profileInvestorData.kycContract === 'object') {
			formData.append('kycContract', profileInvestorData.kycContract)
		}
		if (typeof profileInvestorData.accountOwnership === 'object') {
			formData.append('accountOwnership', profileInvestorData.accountOwnership)
		}

		const { data } = await axios.post('/api/investors', formData, config)

		dispatch({ type: INVESTOR_PROFILE_WITH_USER_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: INVESTOR_PROFILE_WITH_USER_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const deleteInvestorProfile = (profileInfo) => async (dispatch, getState) => {
	try {
		dispatch({ type: INVESTOR_PROFILE_DELETE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.delete(`/api/profiles/${profileInfo._id}`, config)

		dispatch({ type: INVESTOR_PROFILE_DELETE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: INVESTOR_PROFILE_DELETE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getInvestorsProfileById = (profileId) => async (dispatch, getState) => {
	try {
		dispatch({ type: INVESTOR_PROFILE_BY_ID_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		const { data } = await axios.get(`/api/profiles/profile/${profileId}`, config)

		dispatch({ type: INVESTOR_PROFILE_BY_ID_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: INVESTOR_PROFILE_BY_ID_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getPrescriberProfiles = () => async (dispatch, getState) => {
	try {
		dispatch({ type: INVESTOR_PRESCRIBERS_PROFILES_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		const { data } = await axios.get(`/api/prescribers`, config)

		dispatch({ type: INVESTOR_PRESCRIBERS_PROFILES_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: INVESTOR_PRESCRIBERS_PROFILES_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const uploadInvestorProfileDocuments = (documentInfo) => async (dispatch, getState) => {
	try {
		dispatch({ type: INVESTOR_PROFILE_UPLOAD_DOCUMENTS_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		let formData = new FormData()

		for (let key in documentInfo) {
			if (documentInfo[key]) {
				formData.append(key, documentInfo[key])
			}
		}

		const { data } = await axios.post(`/api/profiles/upload-documents/${documentInfo.profileId}`, formData, config)

		dispatch({ type: INVESTOR_PROFILE_UPLOAD_DOCUMENTS_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: INVESTOR_PROFILE_UPLOAD_DOCUMENTS_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const deleteInvestorProfileDocument = (deleteData) => async (dispatch, getState) => {
	try {
		dispatch({ type: INVESTOR_PROFILE_DELETE_DOCUMENT_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		const { data } = await axios.delete(
			`/api/profiles/upload-documents/${deleteData.profileId}/${deleteData.documentName}`,
			config,
		)

		dispatch({ type: INVESTOR_PROFILE_DELETE_DOCUMENT_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: INVESTOR_PROFILE_DELETE_DOCUMENT_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
