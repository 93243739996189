import axios from 'axios'
import * as types from '../constants/logConstants'
import { TEN_DAYS_IN_MILLISECONDS } from 'local_redux/constants/investmentConstants'

export const registerLog = (info) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.LOG_REGISTER_REQUEST })
		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo?.token}`,
			},
		}

		const { data } = await axios.post('/api/logs', info, config)

		dispatch({ type: types.LOG_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.LOG_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getLogs = () => async (dispatch, getState) => {
	try {
		dispatch({ type: types.LOG_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get('/api/logs', config)

		dispatch({ type: types.LOG_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.LOG_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getUsersLastLoginLogs = (query) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.LOG_USERS_LAST_LOGIN_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/logs/users-last-login`, config)

		dispatch({ type: types.LOG_USERS_LAST_LOGIN_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.LOG_USERS_LAST_LOGIN_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getLogById = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.LOG_DETAILS_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/logs/${id}`, config)

		dispatch({ type: types.LOG_DETAILS_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.LOG_DETAILS_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const deleteLog = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.LOG_DELETE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.delete(`/api/logs/${id}`, config)

		dispatch({ type: types.LOG_DELETE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.LOG_DELETE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const deleteAllLogs = () => async (dispatch, getState) => {
	try {
		dispatch({ type: types.LOG_DELETE_MANY_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.delete(`/api/logs`, config)

		dispatch({ type: types.LOG_DELETE_MANY_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.LOG_DELETE_MANY_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getLogsByType = (query) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.LOG_BY_TYPE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}
		let { action, logType } = query

		const { data } = await axios.get(`/api/logs/types?action=${action}&logType=${logType}`, config)

		dispatch({ type: types.LOG_BY_TYPE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.LOG_BY_TYPE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getLogSearch = (filter) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.LOG_SEARCH_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		let query = `type=${filter.type}&log=${filter.log}&startDate=${filter.startDate}&endDate=${filter.endDate}`

		const { data } = await axios.get(`/api/logs/search?${query}`, config)

		dispatch({ type: types.LOG_SEARCH_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.LOG_SEARCH_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getSeeInfoLogs = () => async (dispatch, getState) => {
	try {
		dispatch({ type: types.LOG_PROJECT_SEE_INFO_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		let query = `type=${'action'}&log=${types.LOG_SEE_INFO_PROJECT_ACTION}&startDate=${new Date().getTime()}&endDate=${
			new Date().getTime() + TEN_DAYS_IN_MILLISECONDS / 10
		}`

		const { data } = await axios.get(`/api/logs/see-info?${query}`, config)

		dispatch({ type: types.LOG_PROJECT_SEE_INFO_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.LOG_PROJECT_SEE_INFO_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
