import { Suspense, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import Footer from 'components/Footer/Footer'
import Loader from 'components/Loader/Loader'
import styles from './styles/publicStyles'

const useStyles = makeStyles(styles)

const PublicLayout = ({ ...rest }) => {
  const classes = useStyles()

  useEffect(() => {
    document.body.style.overflow = 'unset'

    return function cleanup() {}
  })

  return (
    <div>
      <div className={classes.fullPage}>
        <Suspense
          fallback={
            <div className={classes.loaderContainer}>
              <Loader />
            </div>
          }
        >
          <Outlet />
        </Suspense>
        <Footer white />
      </div>
    </div>
  )
}

export default PublicLayout
