import axios from 'axios'
import * as types from '../constants/investmentConstants'

export const registerInvestment = (investmentData) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.INVESTMENT_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/investments', investmentData, config)

		dispatch({ type: types.INVESTMENT_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.INVESTMENT_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const reInvestmentRegister = (investmentData) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.RE_INVESTMENT_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/investments/re-investments', investmentData, config)

		dispatch({ type: types.RE_INVESTMENT_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.RE_INVESTMENT_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const declineInvestment = (investment) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.INVESTMENT_ADMIN_DECLINE_REQUEST })
		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}
		const { data } = await axios.delete(`/api/investments/delete/${investment._id}`, config)

		dispatch({ type: types.INVESTMENT_ADMIN_DECLINE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.INVESTMENT_ADMIN_DECLINE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const getInvestmentsByProject = (projectId) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.INVESTMENTS_BY_PROJECT_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}
		const { data } = await axios.get(`/api/investments/project/${projectId}`, config)

		dispatch({ type: types.INVESTMENTS_BY_PROJECT_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.INVESTMENTS_BY_PROJECT_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const getInvestmentsByStatusAndProfile = (profileId, projectStatus) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.INVESTMENTS_BY_STATUS_AND_PROFILE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}
		const { data } = await axios.get(
			`/api/investments/investments-by-status-and-profile/${profileId}/${projectStatus}`,
			config,
		)

		dispatch({ type: types.INVESTMENTS_BY_STATUS_AND_PROFILE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.INVESTMENTS_BY_STATUS_AND_PROFILE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const getInvestmentsByProfileAndProject = (projectId, profileId) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.INVESTMENTS_BY_PROFILE_AND_PROJECT_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}
		const { data } = await axios.get(`/api/investments/project/${projectId}/${profileId}`, config)

		dispatch({ type: types.INVESTMENTS_BY_PROFILE_AND_PROJECT_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.INVESTMENTS_BY_PROFILE_AND_PROJECT_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const getInvestmentsList = () => async (dispatch, getState) => {
	try {
		dispatch({ type: types.INVESTMENT_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}
		const { data } = await axios.get('/api/investments', config)

		dispatch({ type: types.INVESTMENT_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.INVESTMENT_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const getUnfinishedInvestmentsList = () => async (dispatch, getState) => {
	try {
		dispatch({ type: types.UNFINISHED_INVESTMENT_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}
		const { data } = await axios.get(`/api/investments/unfinished-investments`, config)

		dispatch({ type: types.UNFINISHED_INVESTMENT_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.UNFINISHED_INVESTMENT_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const getLiquidatedInvestmentsList =
	(startDate = null, endDate = null) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: types.LIQUIDATED_INVESTMENT_LIST_REQUEST })

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
					'Cache-Control': 'no-cache',
				},
			}
			const { data } = await axios.get(
				`/api/investments/liquidated${startDate && endDate ? `?startDate=${startDate}&endDate=${endDate}` : ''}`,
				config,
			)

			dispatch({ type: types.LIQUIDATED_INVESTMENT_LIST_SUCCESS, payload: data })
		} catch (error) {
			dispatch({
				type: types.LIQUIDATED_INVESTMENT_LIST_FAIL,
				payload: error.response && error.response.data.message ? error.response.data.message : error.message,
			})
		}
	}
export const getInvestmentsProfileProject = (projectId) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.INVESTMENT_PROFILE_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}
		const { data } = await axios.get(`/api/projects/investments/${projectId}`, config)

		dispatch({ type: types.INVESTMENT_PROFILE_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.INVESTMENT_PROFILE_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const getInvestmentById = (investmentId) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.INVESTMENT_BY_ID_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}
		const { data } = await axios.get(`/api/investments/${investmentId}`, config)

		dispatch({ type: types.INVESTMENT_BY_ID_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.INVESTMENT_BY_ID_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const uploadContract = (investment) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.INVESTMENT_UPLOAD_CONTRACT_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		let formData = new FormData()

		if (investment.contractFile) {
			formData.append('contractFile', investment.contractFile)
		}

		const { data } = await axios.put(`/api/investments/contracts/${investment._id}`, formData, config)

		dispatch({ type: types.INVESTMENT_UPLOAD_CONTRACT_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.INVESTMENT_UPLOAD_CONTRACT_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const signContract = (investment) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.INVESTMENT_SIGN_CONTRACT_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		const { data } = await axios.put(`/api/investments/contract-id/admin/${investment._id}`, investment, config)

		dispatch({ type: types.INVESTMENT_SIGN_CONTRACT_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.INVESTMENT_SIGN_CONTRACT_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const getLastInvestmentList = () => async (dispatch, getState) => {
	try {
		dispatch({ type: types.INVESTMENT_LAST_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		const { data } = await axios.get(`/api/investments/last`, config)

		dispatch({ type: types.INVESTMENT_LAST_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.INVESTMENT_LAST_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const getInvestmentsByProfile = (profile) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.INVESTMENT_BY_PROFILE_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		const { data } = await axios.get(`/api/investments/investors`, config)

		dispatch({ type: types.INVESTMENT_BY_PROFILE_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.INVESTMENT_BY_PROFILE_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const getInvestmentsByUserId = (userId, filter) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.INVESTMENT_BY_USER_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}
		const { data } = await axios.get(`/api/investments/user/${userId}?filter=${filter}`, config)

		dispatch({ type: types.INVESTMENT_BY_USER_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.INVESTMENT_BY_USER_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const updateInvestmentPrescriptionLiquidate = (prescriptionsId) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.INVESTMENT_UPDATE_PRESCRIPTION_LIQUIDATED_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}
		const { data } = await axios.put(`/api/investments/prescriptions`, prescriptionsId, config)

		dispatch({ type: types.INVESTMENT_UPDATE_PRESCRIPTION_LIQUIDATED_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.INVESTMENT_UPDATE_PRESCRIPTION_LIQUIDATED_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const getInvestmentsByDateAndInvestor = () => async (dispatch, getState) => {
	try {
		dispatch({ type: types.INVESTMENT_BY_DATE_AND_INVESTOR_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}
		const { data } = await axios.get(`/api/investments/by-kpis`, config)

		dispatch({ type: types.INVESTMENT_BY_DATE_AND_INVESTOR_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.INVESTMENT_BY_DATE_AND_INVESTOR_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getInvestNotesById = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.INVESTS_GET_NOTES_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		const { data } = await axios.get(`/api/invest-notes/${id}`, config)

		dispatch({ type: types.INVESTS_GET_NOTES_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.INVESTS_GET_NOTES_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
		setTimeout(() => {
			dispatch({ type: types.INVESTS_GET_NOTES_FAIL_RESET })
		}, 3000)
	}
}

export const registerInvestNote = (payload) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.INVESTS_GET_NOTES_REQUEST_ADD, payload: true })

		const {
			userLogin: { userInfo },
			investNotes: { currentNotes },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		const currentStateNotes = [...currentNotes]

		const { data } = await axios.post(`/api/invest-notes/`, payload, config)

		currentStateNotes.push(data)

		dispatch({ type: types.INVESTS_GET_NOTES_SUCCESS_ADDED, payload: true })
		dispatch({ type: types.INVESTS_GET_NOTES_SUCCESS, payload: currentStateNotes })
		dispatch({ type: types.INVESTS_GET_NOTES_REQUEST_ADD, payload: false })
		setTimeout(() => {
			dispatch({ type: types.INVESTS_GET_NOTES_SUCCESS_ADDED, payload: false })
		}, 3000)
	} catch (error) {
		dispatch({
			type: types.INVESTS_GET_NOTES_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
		setTimeout(() => {
			dispatch({ type: types.INVESTS_GET_NOTES_FAIL_RESET })
		}, 3000)
	}
}

export const getRetentionCertificateByInvestmentId = (investmentId) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.GET_RETENTION_CERTIFICATE_BY_INVESTMENT_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}
		const { data } = await axios.get(`/api/investments/retention-certificate/${investmentId}`, config)

		dispatch({ type: types.GET_RETENTION_CERTIFICATE_BY_INVESTMENT_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.GET_RETENTION_CERTIFICATE_BY_INVESTMENT_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getContractsInfoToDownload =
	({ page, date, projectKey, investorKey }) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: types.GET_CONTRACTS_INFO_TO_DOWNLOAD_REQUEST })

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			}
			const { data } = await axios.get(
				`/api/investments/contract-info?${page ? '&page=' + page : ''}${
					date.end ? `&start=${date.start}&end=${date.end}` : ''
				}${projectKey ? `&projectKey=${projectKey}` : ''}${investorKey ? `&investorKey=${investorKey}` : ''}`,
				config,
			)

			dispatch({ type: types.GET_CONTRACTS_INFO_TO_DOWNLOAD_SUCCESS, payload: data })
		} catch (error) {
			dispatch({
				type: types.GET_CONTRACTS_INFO_TO_DOWNLOAD_FAIL,
				payload: error.response && error.response.data.message ? error.response.data.message : error.message,
			})
		}
	}

export const updateInvestmentStatus = (body) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.UPDATE_INVESTMENT_STATUS_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}
		const { data } = await axios.put('/api/investments/', body, config)

		dispatch({ type: types.UPDATE_INVESTMENT_STATUS_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.UPDATE_INVESTMENT_STATUS_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const sendPrescriptorContrat = (body) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.SEND_PRESCRIPTOR_CONTRACT_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}
		const { data } = await axios.post('/api/investments/send-contract', body, config)

		dispatch({ type: types.SEND_PRESCRIPTOR_CONTRACT_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.SEND_PRESCRIPTOR_CONTRACT_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
