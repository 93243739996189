import axios from 'axios'
import * as type from '../constants/projectConstant'
import { ALL_PAGES_CHANGE } from 'local_redux/constants/tableConstants'
import { PAGE_CHANGE } from 'local_redux/constants/tableConstants'
import { LOADING_TABLE_PARAMS } from 'local_redux/constants/tableConstants'
import { generateFilterParamsString } from 'shared/helpers/generate-filters-params-string'

export const registerProject = (projectData) => async (dispatch, getState) => {
	try {
		dispatch({ type: type.PROJECT_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}
		const fieldsFiles = ['images']

		let formData = new FormData()

		for (let key in projectData) {
			if (!fieldsFiles.includes(key)) {
				formData.append(key, projectData[key])
			}
		}

		if (projectData.images) {
			formData.append('images', projectData.images)
		}

		const { data } = await axios.post('/api/projects', formData, config)

		dispatch({ type: type.PROJECT_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: type.PROJECT_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const sendProjectSettlementEmail = (body) => async (dispatch, getState) => {
	try {
		dispatch({ type: type.SEND_PROJECT_SETTLEMENT_EMAIL_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/projects/send-project-settlement-email', body, config)

		dispatch({ type: type.SEND_PROJECT_SETTLEMENT_EMAIL_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: type.SEND_PROJECT_SETTLEMENT_EMAIL_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

// this thunk will be only callable in ReactTable Contexts
export const getAllProjects = (params) => async (dispatch, getState) => {
	try {
		dispatch({ type: type.PROJECT_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState() ?? 0
		const config = {
			headers: {
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/projects/get-all-projects${params?`?status=${params}`:''}`, config)

		dispatch({ type: type.PROJECT_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: type.PROJECT_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getAllFinishedProjects = () => async (dispatch, getState) => {
	try {
		dispatch({ type: type.PROJECT_FINISHED_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/projects/finished`, config)

		dispatch({ type: type.PROJECT_FINISHED_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: type.PROJECT_FINISHED_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getAllProjectsToInvest = (page) => async (dispatch, getState) => {
	try {
		dispatch({ type: type.PROJECT_TO_INVEST_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/projects/to-invest?limit=12&page=${page}`, config)

		dispatch({ type: type.PROJECT_TO_INVEST_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: type.PROJECT_TO_INVEST_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getProjectsFromSales = () => async (dispatch, getState) => {
	try {
		dispatch({ type: type.PROJECT_FROM_SALES_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get('/api/projects/sales', config)

		dispatch({ type: type.PROJECT_FROM_SALES_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: type.PROJECT_FROM_SALES_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getProjectsFromPdlLiquidate = () => async (dispatch, getState) => {
	try {
		dispatch({ type: type.PROJECT_FROM_PDL_LIQUIDATE_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get('/api/projects/from-pdl-liquidate', config)

		dispatch({ type: type.PROJECT_FROM_PDL_LIQUIDATE_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: type.PROJECT_FROM_PDL_LIQUIDATE_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getProjectById = (projectId) => async (dispatch, getState) => {
	try {
		dispatch({ type: type.PROJECT_BY_ID_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/projects/${projectId}`, config)

		dispatch({ type: type.PROJECT_BY_ID_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: type.PROJECT_BY_ID_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const updateProject = (projectData) => async (dispatch, getState) => {
	try {
		dispatch({ type: type.PROJECT_UPDATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		let formData = new FormData()

		for (let key in projectData) {
			if (projectData[key]) {
				formData.append(key, projectData[key])
			}
		}

		const { data } = await axios.put(`/api/projects/${projectData._id}`, formData, config)
		dispatch({ type: type.PROJECT_UPDATE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: type.PROJECT_UPDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const deleteProject = (projectId) => async (dispatch, getState) => {
	try {
		dispatch({ type: type.PROJECT_DELETE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.delete(`/api/projects/${projectId}`, config)

		dispatch({ type: type.PROJECT_DELETE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: type.PROJECT_DELETE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const rebuildProjectCsv = (projectId, currentFilePath) => async (dispatch, getState) => {
	try {
		dispatch({ type: type.PROJECT_REBUILD_CSV_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(`/api/projects/rebuild`, { projectId, currentFilePath }, config)

		dispatch({ type: type.PROJECT_REBUILD_CSV_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: type.PROJECT_REBUILD_CSV_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getDelayedProjects = () => async (dispatch, getState) => {
	try {
		dispatch({ type: type.PROJECT_DELAYED_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/projects/delayed`, config)

		dispatch({ type: type.PROJECT_DELAYED_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: type.PROJECT_DELAYED_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getReasonsFromProjectsByProjectId = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: type.REASONS_FROM_PROJECTS_BY_PROJECT_ID_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/projects/delayed/reasons/${id}`, config)

		dispatch({ type: type.REASONS_FROM_PROJECTS_BY_PROJECT_ID_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: type.REASONS_FROM_PROJECTS_BY_PROJECT_ID_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const updateFinishedDateProject = (updateData) => async (dispatch, getState) => {
	try {
		dispatch({ type: type.PROJECT_UPDATE_FINISHED_DATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(`/api/projects/delayed/${updateData._id}`, updateData, config)

		dispatch({ type: type.PROJECT_UPDATE_FINISHED_DATE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: type.PROJECT_UPDATE_FINISHED_DATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const updateSigOfferDataByPdl = (updateData) => async (dispatch, getState) => {
	try {
		dispatch({ type: type.SIG_PROJECT_UPDATE_BY_PDL_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put('api/projects/sig-offer-data', updateData, config)

		dispatch({ type: type.SIG_PROJECT_UPDATE_BY_PDL_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: type.SIG_PROJECT_UPDATE_BY_PDL_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getProjectsWithPDLToInvest = () => async (dispatch, getState) => {
	try {
		dispatch({ type: type.PROJECT_PDL_TO_INVEST_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get('/api/projects/pdl-invest', config)

		dispatch({ type: type.PROJECT_PDL_TO_INVEST_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: type.PROJECT_PDL_TO_INVEST_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getAcceptedProjectOffer = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: type.PROJECT_ACCEPTED_OFFER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get('/api/projects/get-accepted-offer/' + id, config)

		dispatch({ type: type.PROJECT_ACCEPTED_OFFER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: type.PROJECT_ACCEPTED_OFFER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
